import React from 'react'
import WhatsappIcon from '../whatsapp-icon'

export default function Encomendar({msg}) {
  const message = msg
    ? msg
    : "Faça a sua encomenda"
  return <div>
    <button class="uk-button uk-button-default" data-uk-toggle="target: #encomendar-modal" type="button">{message}</button>
    <div id="encomendar-modal" data-uk-modal="data-uk-modal">
      <div class="uk-modal-dialog uk-modal-body">
        <h2 class="uk-modal-title">Faça a sua encomenda</h2>
        <div data-uk-grid="data-uk-grid" className="uk-grid-collapse">
          <div className="uk-width-1-2">Telemóvel:</div>
          <div className="uk-width-1-2">
            <a href="tel:351910258913" target="_blank" rel="noreferrer">910 258 913</a>
          </div>
          <div className="uk-width-1-2">WhatsApp:</div>
          <div className="uk-width-1-2">
            <a href="https://wa.me/351910258913" target="_blank" rel="noreferrer"><WhatsappIcon/>{" "}</a>
          </div>
        </div>
        <p>Faço tudo fresco na hora. As encomendas são feitas na véspara. Todos os pratos são personalizados.</p>
        <p className="uk-text-lead">Bem vindo!</p>
        <p className="uk-text-right">
          <button className="uk-button uk-button-default uk-margin-top uk-modal-close" type="button">Fechar</button>
        </p>
      </div>
    </div>
  </div>
}
