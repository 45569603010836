import React, {useEffect, useState} from 'react';
import {Helmet} from 'react-helmet'

import SiteMeta from "../queries/site-meta"
import './app.scss'

export default function App({title, description, image, location, children}) {
  const [ready, setReady] = useState(false);
  useEffect(() => {
    if (typeof window !== 'undefined') {
      const uikit = require('uikit');
      const icons = require('uikit/dist/js/uikit-icons.min');
      uikit.use(icons);
      setReady(true)
    }
  });

  return <React.Fragment>
    <Helmet>
      <meta charset="utf-8"/>
      <meta name="description" content={description}/>
      <meta property="og:url" content={SiteMeta().url + location.pathname}/>
      <meta property="og:type" content="website"/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={SiteMeta().url + image}/>
      <meta property="og:image.alt" content={title}/>
      <title>{title}
        | DELINI.PT</title>
    </Helmet>
    {ready && children}
  </React.Fragment>
}
