import React from 'react'

export default function Banner({title, bannerImage}) {
  return <section className="uk-section uk-section-small">
    <div className="uk-inline">
      {bannerImage}
      <div className="uk-overlay uk-position-top uk-light uk-text-center">
        <h1 className="uk-heading-medium">
          {title}
        </h1>
      </div>
    </div>
  </section>
}
