import {useStaticQuery, graphql} from "gatsby"

export default function SiteMeta () {

  const data = useStaticQuery(graphql `
    query {
      site {
        siteMetadata {
          url
          title
        }
      }
    }`)

  return {
    url: data.site.siteMetadata.url,
    title: data.site.siteMetadata.title,
  }

}
