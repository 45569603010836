import React from 'react'
import {StaticImage} from "gatsby-plugin-image"
import WhatsappIcon from '../whatsapp-icon'
import * as styles from './footer.module.scss'

export default function Footer() {
  const avatar = <StaticImage className={styles.avatar} src="../../assets/images/avatar.jpg" alt="Eveline" layout="fixed" width={100}/>
  return <section className="uk-section uk-section-medium uk-background-primary uk-light">
    <div className="uk-container">
      <div data-uk-grid="data-uk-grid" className="uk-flex-middle uk-flex-center">
        <div>
          {avatar}
        </div>
        <div>
          <h2>Contacto e Encomendas</h2>
          <p>Eveline Nunes de Andrade<br/>Rua 25 de Abril Lote 10<br/>2955-123 Pinhal Novo</p>
          <div className="uk-grid-collapse" data-uk-grid="data-uk-grid">
            <div className="uk-width-1-2">Telemovel:</div>
            <div className="uk-width-1-2">
              <a href="tel:351910258913" target="_blank" rel="noreferrer">910 258 913</a>
            </div>
            <div className="uk-width-1-2">WhatsApp:</div>
            <div className="uk-width-1-2">
              <a href="https://wa.me/351910258913" target="_blank" rel="noreferrer"><WhatsappIcon/>{" "}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

}
