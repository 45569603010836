import React from 'react'
import {Link} from 'gatsby'

export default function Navbar() {
  return <nav className="uk-navbar uk-navbar-container">
    <div className="uk-navbar-center">
      <div className="uk-navbar-item uk-logo">
        <button className="uk-button uk-button-default" type="button">delini.pt</button>
        <div uk-dropdown="uk-dropdown">
          <ul className="uk-nav uk-dropdown-nav uk-text-uppercase">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/lasanha/">Lasanha</Link>
            </li>
            <li>
              <Link to="/feijoada-brasileira/">Feijoada Brasileira</Link>
            </li>
            <li>
              <Link to="/pao-de-queijo/">Pão de Queijo</Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

}

/*
<li>
  <Link to="/vinho/">Vinhos Seleccionados</Link>
</li>
*/
