import React from "react"

import Banner from './banner'
import Footer from '../footer'
import Navbar from '../navbar'

export default function BannerLayout({title, bannerImage, children}) {
  return <React.Fragment>
    <header>
      <Navbar/>
      <Banner title={title} bannerImage={bannerImage}/>
    </header>
    <main>
      {children}
    </main>
    <footer>
      <Footer />
    </footer>
  </React.Fragment>
}
